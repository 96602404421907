<script lang="ts">
	import LinkCard from "./LinkCard.svelte";
	import { getTranslate } from "../utils/getTranslate.js";
	import translations from "./CanBeInterested.translations.json";
	import { flyInOnScroll } from "../utils/flyInOnScroll.js";
	import Link from "./Link.svelte";
	import { newsPathMap } from "../../../core/schema/paths/newsPathMap.js";
	import { getTenant } from "../contexts/tenantContextKey.js";
	import { getPathInLocale } from "../../../core/schema/paths/getPathInLocale.js";
	import { recentNewsAmountNews } from "../../../core/schema/newsAmount";
	import type { NewsForCard } from "./BlogPostCardProps.js";
	import { getNewsDetailPath } from "../../../core/schema/paths/getNewsDetailPath.js";

	export let news: NewsForCard[];

	const { locale } = getTenant();
	const translate = getTranslate(translations);
</script>

<div class="bg-tertiary py-20 lg:py-48">
	<div class="container">
		<div class="mb-[3.25rem] items-center justify-between lg:mb-[6.25rem] lg:flex">
			<h2 use:flyInOnScroll class="text-xl font-semibold md:w-full md:text-2xl lg:text-4xl">
				{translate("couldBeInterested")}
			</h2>
			<Link asButton variant="tertiary" href="/{getPathInLocale(locale, newsPathMap)}" class="ml-auto hidden lg:flex">
				<span>{translate("moreNews")}</span>
			</Link>
		</div>
		<div class="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
			{#each news as post, index}
				{#if index <= recentNewsAmountNews}
					<LinkCard
						{index}
						link="/{getNewsDetailPath(post)}"
						class="bg-white"
						title={post.title}
						sectionName={translate("newsCategory")}
						backgroundImage={post.primaryImage}
						backgroundImageOverlay
						fixedBgOnMobile
						darkFilter
					/>
				{/if}
			{/each}
		</div>
	</div>
</div>
